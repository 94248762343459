import React, {useEffect} from 'react';
import {useIdleTimer} from 'react-idle-timer';

import {useUrlSearchParams} from 'use-url-search-params';
import AppContentView from '@crema/components/AppContentView';
import generateRoutes from '@crema/helpers/RouteGenerator';
import {Layouts} from '@crema/components/AppLayout';
// import {useAuthUser} from '@crema/hooks/AuthHooks';
import {useAuthUser, logout} from '../../../hooks/AuthHooks';

import {
  useLayoutActionsContext,
  useLayoutContext,
} from '@crema/context/AppContextProvider/LayoutContextProvider';
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from '../AppRoutes';
import {useRoutes} from 'react-router-dom';
import routesConfig from '../AppRoutes/routeConfig';
import routeConfigAdmin from '../AppRoutes/routeConfigAdmin';
import routesConfigAccountant from '../AppRoutes/routeConfigAccountant';
import AuthWrapper from './AuthWrapper';
import {
  initialUrl,
  initialUrlAccountant,
  initialUrlAdmin,
} from '@crema/constants/AppConst';
import {useSidebarActionsContext} from '@crema/context/AppContextProvider/SidebarContextProvider';
// import {Default} from '../../../components/layout/';

const AppLayout = () => {
  const {navStyle} = useLayoutContext();

  const {user, isAuthenticated} = useAuthUser();
  const [customRouts, setCustomRouts] = React.useState(routesConfig);
  const [customInitialUrl, setCustomInitialUrl] = React.useState(initialUrl);

  useEffect(() => {
    if (user?.roleId == 1 || user?.roleId == 2) {
      setCustomRouts(routeConfigAdmin);
      setCustomInitialUrl(initialUrlAdmin);
    } else if (user?.roleId == 3 || user?.roleId == 4) {
      setCustomRouts(routesConfig);
      setCustomInitialUrl(initialUrl);
    } else if (user?.roleId == 5) {
      setCustomRouts(routesConfigAccountant);
      setCustomInitialUrl(initialUrlAccountant);
    } else {
      setCustomRouts([]);
      setCustomInitialUrl('/');
    }
  }, [user]);

  /* Idle Timer */
  const onIdle = () => {
    // Do some idle action like log out your user
    // logout();
    const currentUrl = window.location.pathname;
    if (
      currentUrl != '/signin' &&
      currentUrl != '/signup' &&
      currentUrl != '/'
    ) {
      logout();
    }
    console.log('user is idle');
  };
  const {getRemainingTime, getLastActiveTime} = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: onIdle,
    debounce: 500,
  });

  /* End Idle Timer */

  const {updateNavStyle} = useLayoutActionsContext();
  const {updateMenuStyle, setSidebarBgImage} = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  //   const AppLayout = Default;
  const [params] = useUrlSearchParams();
  //   console.info(Default);

  //   console.info('src/%40crema/core/AppLayout/index.js', 'AppLayout()');
  //   console.info('AppLayout() user', user);
  //   console.info('AppLayout() isAuthenticated', isAuthenticated);

  const initURL = params?.redirect ? params?.redirect : customInitialUrl;
  const loginUrl = `/signin?redirect=${window.location.pathname}`;
  console.info('initURL', initURL);
  console.info('loginUrl', loginUrl);
  const generatedRoutes = generateRoutes({
    isAuthenticated: isAuthenticated,
    userRole: user?.role,
    anonymousStructure: anonymousStructure(initURL),
    authorizedStructure: authorizedStructure(loginUrl),
    unAuthorizedStructure: unAuthorizedStructure(initURL),
  });

  const routes = useRoutes(generatedRoutes);

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout);
    if (params.menuStyle) updateMenuStyle(params.menuStyle);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [
    params.layout,
    params.menuStyle,
    params.sidebarImage,
    updateNavStyle,
    updateMenuStyle,
    setSidebarBgImage,
  ]);

  return (
    <>
      {isAuthenticated ? (
        <AppLayout routes={routes} routesConfig={customRouts} />
      ) : (
        <AuthWrapper>
          <AppContentView routes={routes} />
        </AuthWrapper>
      )}
    </>
  );
};

export default AppLayout;
